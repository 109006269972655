<template>
  <div class="message" v-if="token">
    <el-popover placement="bottom" :width="400" trigger="hover">
      <template #reference>
        <el-badge :value="unreadCount" :max="99" :hidden="unreadCount == 0">
          <i class="el-icon-bell"></i>
        </el-badge>
      </template>
      <div class="message-empty" v-if="list.length === 0">
        <img src="@/assets/images/notData.png" alt="notData" />
        <div>暂无消息</div>
      </div>
      <div class="message-list" v-else>
        <div class="message-item" v-for="(item, index) in list" :key="index">
          <img :src="avatar" alt="" class="message-icon" />
          <div class="message-content">
            <span class="message-title">{{ item.caseTitle }}</span>
            <span class="show3">{{ item.content }}</span>
          </div>
        </div>
      </div>
      <div style="text-align: center">
        <el-button type="text" @click="showMessage">查看全部消息</el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { allUnreadMessage, newMessage } from '@/api/messageApi'
import avatar from '../../assets/images/msg01.png'
export default {
  props: ['isCollapse'],
  data() {
    return {
      avatar,
      userInfoUrl: process.env.VUE_APP_USERINFOURL,
      list: []
    }
  },
  methods: {
    ...mapMutations('user', ['setToken', 'setUserInfo', 'clearToken', 'clearUserInfo']),
    ...mapMutations('message', ['setUnreadCount']),

    showMessage() {
      this.$router.push('/message')
    },
    getCount() {
      allUnreadMessage().then((res) => {
        this.setUnreadCount(res.data)
      })
    },
    getNewMessage() {
      newMessage().then((res) => {
        this.list = res.data
      })
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'token', 'unreadCount'])
  },
  mounted() {
    if (this.token) {
      this.getCount()
      this.getNewMessage()
      this.timer = setInterval(this.getCount, 120000) // 1000毫秒=1秒钟  120000为两分钟
      this.timer2 = setInterval(this.getNewMessage, 120000) // 1000毫秒=1秒钟  120000为两分钟
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.timer2)
  }
}
</script>

<style scoped lang="less">
.message-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  .message-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ebeef5;
    &:last-child {
      border: none;
    }
    .message-icon {
      width: 30px;
      height: 30px;
      margin: 0 5px;
    }
    .message-content {
      width: calc(100% - 35px);
      display: flex;
      flex-direction: column;
      .message-title {
        color: @primary;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.message-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
  line-height: 45px;
}
</style>
