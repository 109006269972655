<template>
  <i @click="setCollapse" :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"></i>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isCollapse'])
  },
  methods: {
    ...mapMutations('setting', ['setCollapse'])
  }
}
</script>

<style lang="less" scoped>
.el-icon-s-unfold,
.el-icon-s-fold {
  cursor: pointer;
  font-size: 22px;
  color: #000;
}
</style>
