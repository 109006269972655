import Vue from 'vue'
import './element'
import directive from '../directive'
import global from './global'

import AddCase from '@/components/AddCase.vue'
Vue.use(directive)

Vue.prototype.$global = global

Vue.component('AddCase', AddCase)
