export default {
  namespaced: true,

  state: {
    unreadCount: 0
  },

  actions: {},

  mutations: {
    // 设置unreadCount
    setUnreadCount(state, payload) {
      state.unreadCount = payload
    }
  }
}
