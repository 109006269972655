<template>
  <div v-if="token">
    <div class="add-case" @click="addCase">
      <i class="el-icon-plus" size="25"></i>
      <span>工单</span>
    </div>
    <AddCase ref="addCase" title="新建工单" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isCollapse', 'token'])
  },
  methods: {
    ...mapMutations('setting', ['setCollapse']),
    addCase() {
      this.$refs.addCase.addDialogVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.add-case {
  position: fixed;
  z-index: 6;
  bottom: 5%;
  right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: @primary;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  i {
    font-size: 25px;
  }
  span {
    font-size: 14px;
    margin-top: 3px;
  }
}
</style>
