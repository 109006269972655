import store from '@/store'

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/auth/directive.vue
 */
export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    const auths = (store.getters.userInfo && store.getters.userInfo.authorities) || []
    const permissionAuths = value
    const hasPermission = auths.some((auth) => {
      return permissionAuths.includes(auth)
    })

    if (!hasPermission) {
      return false
    }
    return true
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"")
    return false
  }
}
