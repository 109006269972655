<template>
  <div id="uploader">
    <!-- 上传 -->
    <uploader
      ref="uploader"
      :options="initOptions"
      :fileStatusText="fileStatusText"
      :autoStart="false"
      @file-added="onFileAdded"
      @file-success="onFileSuccess"
      @file-progress="onFileProgress"
      @file-error="onFileError"
      class="uploader-app"
    >
      <uploader-unsupport></uploader-unsupport>
      <uploader-btn id="uploader-btn" ref="uploadBtn">选择文件</uploader-btn>
      <uploader-list v-show="panelShow">
        <template slot-scope="props">
          <ul class="file-list">
            <li class="file-item" v-for="file in props.fileList" :key="file.id">
              <uploader-file
                :class="['file_' + file.id, customStatus]"
                ref="files"
                :file="file"
                :list="true"
              ></uploader-file>
            </li>
          </ul>
        </template>
      </uploader-list>
    </uploader>
  </div>
</template>

<script>
/**
 *  全局上传插件，两种调用方式
 *   1. 作为全局页面的组件，使用event bus
 *   调用方法：Bus.$emit('openUploader', {params: {}, options: {}})
 *               params: 发送给服务器的额外参数；
 *               options：上传选项，目前支持 target、testChunks、mergeFn、accept
 *
 *   监听函数：Bus.$on('fileAdded', fn); 文件选择后的回调
 *           Bus.$on('fileSuccess', fn); 文件上传成功的回调，监听后记得释放
 *
 *   2. 作为普通组件在单个页面中调用，使用props
 */
import { ACCEPT_CONFIG } from './utils/config'
import Bus from './utils/bus'
import SparkMD5 from 'spark-md5'
import { mergeSimpleUpload } from '@/utils'
import { getToken } from '@/utils/token'

export default {
  props: {
    global: {
      type: Boolean,
      default: false
    },
    // 发送给服务器的额外参数
    params: {
      type: Object
    },
    options: {
      type: Object
    }
  },

  data() {
    return {
      initOptions: {
        target: 'http://localhost:3000/upload',
        // singleFile: true,
        chunkSize: '5120000',
        fileParameterName: 'files',
        maxChunkRetries: 3,
        // 是否开启服务器分片校验
        testChunks: true,
        // 服务器分片校验函数，秒传及断点续传基础
        checkChunkUploadedByResponse: function (chunk, message) {
          console.log('chunk--message', message, '-----', message)
          let skip = false
          try {
            const objMessage = JSON.parse(message).data
            if (objMessage.uploaded) {
              skip = true
            } else {
              skip = (objMessage.uploadedChunks || []).indexOf(chunk.offset + 1) >= 0
            }
          } catch (e) {}

          return skip
        },
        query: (file, chunk) => {
          return {
            ...file.params
          }
        },
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      },
      fileStatusText: {
        success: '上传成功',
        error: '上传失败',
        uploading: '上传中',
        paused: '已暂停',
        waiting: '等待上传'
      },
      panelShow: false, // 选择文件后，展示上传panel
      collapse: false,
      customParams: {},
      customStatus: ''
    }
  },

  watch: {
    params: {
      handler(data) {
        if (data) {
          this.customParams = data
        }
      },
      immediate: true
    },
    options: {
      handler(data) {
        if (data) {
          setTimeout(() => {
            this.customizeOptions(data)
          }, 0)
        }
      },
      immediate: true
    }
  },

  mounted() {
    Bus.$on('openUploader', ({ params = {}, options = {} }) => {
      this.customParams = params

      this.customizeOptions(options)

      if (this.$refs.uploadBtn) {
        this.$refs.uploadBtn.$el.click()
      }
    })
  },

  computed: {
    // Uploader实例
    uploader() {
      return this.$refs.uploader.uploader
    }
  },

  methods: {
    // 自定义options
    customizeOptions(opts) {
      // 自定义上传url
      if (opts.target) {
        this.uploader.opts.target = opts.target
      }

      // 是否可以秒传、断点续传
      if (opts.testChunks !== undefined) {
        this.uploader.opts.testChunks = opts.testChunks
      }

      // merge 的方法，类型为Function，返回Promise
      this.mergeFn = opts.mergeFn || mergeSimpleUpload

      // 自定义文件上传类型
      const input = document.querySelector('#uploader-btn input')
      const accept = opts.accept || ACCEPT_CONFIG.getAll()
      input.setAttribute('accept', accept.join())
    },

    onFileAdded(file) {
      this.panelShow = true
      this.emit('fileAdded')

      // 将额外的参数赋值到每个文件上，以不同文件使用不同params的需求
      file.params = this.customParams

      // 计算MD5
      this.computeMD5(file).then((result) => this.startUpload(result))
    },

    /**
     * 计算md5值，以实现断点续传及秒传
     * @param file
     * @returns Promise
     */
    computeMD5(file) {
      const fileReader = new FileReader()
      const time = new Date().getTime()
      const blobSlice =
        File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let currentChunk = 0
      const chunkSize = 10 * 1024 * 1000
      const chunks = Math.ceil(file.size / chunkSize)
      const spark = new SparkMD5.ArrayBuffer()

      // 文件状态设为"计算MD5"
      this.statusSet(file.id, 'md5')
      file.pause()

      loadNext()

      return new Promise((resolve, reject) => {
        fileReader.onload = (e) => {
          spark.append(e.target.result)

          if (currentChunk < chunks) {
            currentChunk++
            loadNext()

            // 实时展示MD5的计算进度
            this.$nextTick(() => {
              const md5ProgressText = '校验MD5 ' + ((currentChunk / chunks) * 100).toFixed(0) + '%'
              console.log('md5ProgressText', md5ProgressText)
              document.querySelector(`.custom-status-${file.id}`).innerText = md5ProgressText
            })
          } else {
            const md5 = spark.end()

            // md5计算完毕
            resolve({ md5, file })

            console.log(
              `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${file.size} 用时：${
                new Date().getTime() - time
              } ms`
            )
          }
        }

        fileReader.onerror = function () {
          this.error(`文件${file.name}读取出错，请检查该文件`)
          file.cancel()
          reject()
        }
      })

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
      }
    },

    // md5计算完毕，开始上传
    startUpload({ md5, file }) {
      file.uniqueIdentifier = md5
      file.resume()
      this.statusRemove(file.id)
    },

    onFileSuccess(rootFile, file, response, chunk) {
      const res = JSON.parse(response)
      console.log('onFileSuccess----response', res)

      // 服务端自定义的错误（即http状态码为200，但是是错误的情况），这种错误是Uploader无法拦截的
      // if (!res.data.result) {
      //   this.error(res.message)
      //   // 文件状态设为“失败”
      //   this.statusSet(file.id, 'failed')
      //   return
      // }

      // 如果服务端返回了需要合并的参数

      if (!res.data.url) {
        // 文件状态设为“合并中”
        this.statusSet(file.id, 'merging')

        this.mergeFn({
          filename: file.name,
          identifier: file.uniqueIdentifier
        })
          .then((url) => {
            // 文件合并成功
            this.emit('fileSuccess', { id: res.data.id, name: file.name, url })

            this.statusRemove(file.id)
          })
          .catch((e) => {})

        // 不需要合并
      } else {
        this.emit('fileSuccess', { id: res.data.id, name: file.name, url: res.data.url })
        console.log('上传成功')
      }
    },

    onFileProgress(rootFile, file, chunk) {
      console.log(
        `上传中 ${file.name}，chunk：${chunk.startByte / 1024 / 1024} ~ ${
          chunk.endByte / 1024 / 1024
        }`
      )
    },

    onFileError(rootFile, file, response, chunk) {
      this.error(response)
    },

    close() {
      this.uploader.cancel()

      this.panelShow = false
    },

    /**
     * 新增的自定义的状态: 'md5'、'merging'、'transcoding'、'failed'
     * @param id
     * @param status
     */
    statusSet(id, status) {
      const statusMap = {
        md5: {
          text: '校验MD5',
          bgc: '#fff'
        },
        merging: {
          text: '合并中',
          bgc: '#e2eeff'
        },
        transcoding: {
          text: '转码中',
          bgc: '#e2eeff'
        },
        failed: {
          text: '上传失败',
          bgc: '#e2eeff'
        }
      }

      this.customStatus = status
      this.$nextTick(() => {
        const statusTag = document.createElement('p')
        statusTag.className = `custom-status-${id} custom-status`
        statusTag.innerText = statusMap[status].text
        statusTag.style.backgroundColor = statusMap[status].bgc

        const statusWrap = document.querySelector(`.file_${id} .uploader-file-status`)
        statusWrap.appendChild(statusTag)
      })
    },

    statusRemove(id) {
      this.customStatus = ''
      this.$nextTick(() => {
        const statusTag = document.querySelector(`.custom-status-${id}`)
        console.log('statusTag', statusTag)
        statusTag.remove()
      })
    },

    emit(e, data) {
      Bus.$emit(e)
      this.$emit(e, data)
    },

    error(msg) {
      this.$notify({
        title: '错误',
        message: msg,
        type: 'error',
        duration: 2000
      })
    }
  }
}
</script>

<style lang="less">
#uploader {
  // width: 100%;
  // .uploader-app {
  //   padding: 25px;
  //   box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  // }

  .uploader-file {
    &.md5 {
      .uploader-file-resume {
        display: none;
      }
    }
  }

  .uploader-file-icon {
    &:before {
      content: '' !important;
    }

    &[icon='image'] {
      background: url(./images/image-icon.png);
    }
    &[icon='audio'] {
      background: url(./images/audio-icon.png);
      background-size: contain;
    }
    &[icon='video'] {
      background: url(./images/video-icon.png);
    }
    &[icon='document'] {
      background: url(./images/text-icon.png);
    }
    &[icon='unknown'] {
      background: url(./images/zip.png) no-repeat center;
      background-size: contain;
    }
  }

  .uploader-file-actions > span {
    margin-right: 6px;
  }

  .custom-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  // .uploader-file-status{
  //   width: 24%;
  // }
}

#uploader-btn {
  border: 0;
  color: @primary;
}

.uploader-drop {
  padding: 40px;
  text-align: center;
  border-color: var(--el-border-color);
  background-color: #fff;
  &:hover {
    border-color: var(--el-color-primary);
  }
  .el-icon {
    color: var(--el-text-color-placeholder) !important;
    margin-bottom: 20px;
  }
}
</style>
