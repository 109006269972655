import moment from 'moment'
import xss from 'xss'
// 工单所有的状态
export const allStatus = {
  10: '进行中',
  11: '新建',
  12: '已开启',
  13: '处理中',
  20: '已解决',
  21: '待确认',
  22: '已完成',
  30: '已关闭',
  31: '已关闭',
  999: '异常'
}
// 工单优先级配置项
export const severityOptions = [
  {
    value: 'S1',
    label: 'S1'
  },
  {
    value: 'S2',
    label: 'S2'
  },
  {
    value: 'S3',
    label: 'S3'
  },
  {
    value: 'S4',
    label: 'S4'
  }
]
export const formLabelWidth80 = '80px'
export const formLabelWidth95 = '95px'
export const formatTime = function (time) {
  return moment(time).format('YYYY-MM-DD HH:mm:ss')
}
export const formatToTimeStamp = function (time) {
  return moment(time).format('x')
}
export function getSafeHtml(html) {
  const whiteList = xss.whiteList
  !whiteList.span.includes('data-info') && whiteList.span.push('data-info')
  !whiteList.img.includes('style') && whiteList.img.push('style')
  const options = {
    whiteList: whiteList
  }
  return xss(html, options)
}
export function isWangEditorEmpty(str) {
  const text = str
    .replace(/<[^<p>]+>/g, '') // 将所有<p>标签 replace ''
    .replace(/<[</p>$]+>/g, '') // 将所有</p>标签 replace ''
    .replace(/&nbsp;/gi, '') // 将所有 空格 replace ''
    .replace(/<[^<br/>]+>/g, '') // 将所有 换行符 replace ''

  if (text === '') return true
  const regu = '^[ ]+$'
  const re = new RegExp(regu)
  return re.test(text)
}