<template>
  <el-upload
    ref="upload"
    :action="uploadUrl"
    :name="name"
    multiple
    :headers="headers"
    :file-list="fileList"
    :on-remove="handleRemove"
    :on-success="handleSuccess"
    :on-error="handleError"
    :disabled="disabled"
  >
    <el-button>上传附件</el-button>
  </el-upload>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteFile } from '@/api'
import { getToken } from '@/utils/token'
export default {
  props: ['uploadUrl', 'name', 'fileList', 'disabled'],
  data() {
    return {
      headers: { Authorization: 'Bearer ' + getToken() }
    }
  },
  methods: {
    // 文件列表移除文件时的钩子
    handleRemove(file) {
      deleteFile({ fileId: file.id, name: file.name }).then((res) => {
        this.$emit('Remove', file.id)
      })
    },
    // 文件上传成功时的钩子
    handleSuccess(response) {
      this.$emit('Success', response)
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$emit('Error', err, file, fileList)
    }
  },
  computed: {
    ...mapGetters(['token'])
  }
}
</script>

<style></style>
