<template>
  <el-menu-item
    v-if="!item.children || (item.children.length === 1 && item.children[0].path === '')"
    :index="item.path"
  >
    <i :class="['iconfont', item.meta.icon]"></i>
    <span slot="title">{{ item.meta.title }}</span>
  </el-menu-item>
  <!-- 如果当前 item 有子节点 -->
  <el-submenu v-else :index="item.path">
    <!-- 创建菜单分组 -->
    <template slot="title">
      <i :class="['iconfont', item.meta.icon]"></i>
      <span slot="title">{{ item.meta.title }}</span>
    </template>

    <template v-for="child in item.children">
      <!-- 递归调用自身，直到 item 不含子节点 -->
      <MenuItem
        v-if="child.children && child.children.length > 0"
        :item="child"
        :key="child.index"
      />
      <!-- 当前节点不含子节点且非隐藏 -->
      <el-menu-item v-else :key="child.index" :index="child.path">
        <span slot="title">{{ child.meta.title }}</span>
      </el-menu-item>
    </template>
  </el-submenu>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    item: {
      type: Object
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.el-submenu {
  span {
    font-size: 14px !important;
  }
}
.el-menu-item {
  font-size: 14px !important;
}
.el-submenu .el-menu-item {
  padding-left: 60px !important;
}
.el-menu-item [class^='iconfont'],
.el-submenu [class^='iconfont'] {
  margin-right: 10px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
}
</style>
