import Layout from '@/views/layout/index.vue'
const Home = () => import('@/views/home/index.vue')
const MyCase = () => import('@/views/caseManage/MyCase/index.vue')
const CaseAssign = () => import('@/views/caseManage/CaseAssign/index.vue')
const CaseDetails = () => import('@/views/caseManage/CaseDetails/index.vue')
const CaseDraft = () => import('@/views/caseManage/CaseDraft/index.vue')
const Product = () => import('@/views/productManage/index.vue')
const Contacts = () => import('@/views/contacts/index.vue')
const ProductDetails = () => import('@/views/productManage/ProductDetails/index.vue')
const Message = () => import('@/views/message/index.vue')
const ErrorPage = () => import('../views/404.vue')
const Customer = () => import('../views/setting/customer/index.vue')
const Log = () => import('../views/setting/log/index.vue')

const anyRoute = {
  path: '*',
  redirect: '/404',
  hidden: true
}
const asyncRoutes = [
  {
    path: '/case',
    name: 'case',
    component: Layout,
    hidden: false,
    meta: {
      title: '工单',
      icon: 'icon-gongdan'
    },
    children: [
      {
        path: '/case/mycase',
        name: 'myCase',
        component: MyCase,
        hidden: false,
        meta: {
          icon: 'icon-gongdan',
          title: '我的工单'
        }
      },
      {
        path: '/case/caseassign',
        name: 'caseAssign',
        component: CaseAssign,
        hidden: false,
        meta: {
          title: '工单列表',
          parent: 'case'
        }
      },

      {
        path: '/case/casedetails/:id',
        name: 'caseDetails',
        component: CaseDetails,
        hidden: true,
        meta: {
          title: '工单详情'
        }
      },

      {
        path: '/case/caseDraft',
        name: 'caseDraft',
        component: CaseDraft,
        hidden: true,
        meta: {
          title: '工单草稿'
        }
      }
    ]
  },
  {
    path: '/setting',
    redirect: '/setting/customer',
    name: 'setting',
    component: Layout,
    hidden: false,
    meta: {
      title: '平台设置',
      icon: 'icon-gongdan'
    },
    children: [
      {
        path: '/setting/customer',
        name: 'customer',
        component: Customer,
        hidden: false,
        meta: {
          icon: 'icon-gongdan',
          title: '客户管理'
        }
      },
      {
        path: '/setting/log',
        name: 'log',
        component: Log,
        hidden: false,
        meta: {
          icon: 'icon-gongdan',
          title: '日志'
        }
      }
    ]
  },
  {
    path: '/contacts',
    component: Layout,
    hidden: false,
    name: 'contacts',
    meta: {
      title: '通讯录',
      icon: 'icon-chanpin'
    },
    children: [
      {
        path: '',
        name: 'contacts-item',
        component: Contacts,
        hidden: false,
        meta: {
          title: '通讯录'
        }
      }
    ]
  }
]
const constantRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
    hidden: true
  },
  {
    path: '/dashboard',
    component: Layout,
    hidden: false,
    meta: {
      title: '首页',
      icon: 'icon-shouye'
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        hidden: false,
        meta: {
          title: '首页'
        }
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    hidden: false,
    meta: {
      title: '产品与文档',
      icon: 'icon-chanpin'
    },
    children: [
      {
        path: '',
        name: 'product',
        component: Product,
        hidden: false,
        meta: {
          title: '产品'
        }
      },
      {
        path: '/product:id',
        name: 'productDetails',
        component: ProductDetails,
        hidden: true,
        meta: {
          title: '产品详情'
        }
      }
    ]
  },
  {
    path: '/message',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        name: 'message',
        component: Message,
        hidden: true,
        meta: {
          title: '消息'
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: ErrorPage,
    hidden: true
  }
]
export { constantRoutes, asyncRoutes, anyRoute }
