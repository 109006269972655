<template>
  <div class="aside-box">
    <div class="log-box">
      <img :src="logoImg" alt="Whaleal Support" />
      <span class="title" v-show="!isCollapse">Whaleal Support</span>
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#141a27"
      text-color="#fff"
      active-text-color="#409eff"
      unique-opened
      router
      :collapse="isCollapse"
    >
      <template v-for="(menu, index) in menus">
        <MenuItem :item="menu" :key="index" />
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuItem from './MenuItem.vue'
import logoImg from '@/assets/logo.jpg'
import checkPermission from '@/utils/permission'
export default {
  name: 'MyMenu',
  components: {
    MenuItem
  },
  data() {
    return {
      logoImg,
      menus: []
    }
  },
  methods: {
    checkPermission,
    getMenu(routes) {
      const result = routes.filter((route) => {
        return route.hidden === false
      })
      result.forEach((item) => {
        if (item.children && item.children.length) {
          item.children = this.getMenu(item.children)
        }
      })
      return result
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'isCollapse', 'menuRoutes'])
  },

  mounted() {
    const newMenu = this.getMenu(this.menuRoutes)
    let arr = []
    if (this.userInfo.userId) {
      const hasPermission = checkPermission(['SUPPORT_JINMU_COMPANY_MEMBER'])
      if (hasPermission) {
        arr = ['/dashboard', '/case', '/setting', '/contacts', '/product']
      } else {
        arr = ['/dashboard', '/case', '/contacts', '/product']
      }
      arr.forEach((item) => {
        this.menus.push(newMenu.find((i) => i.path === item))
      })
    } else {
      this.menus = newMenu
    }
  }
}
</script>

<style scoped lang="less">
.aside-box {
  height: 100%;
}
.log-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #141a27;
  border-bottom: 1px dotted #fff;
  img {
    width: 40px;
  }
  .title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }
}
.el-menu {
  height: calc(100% - 60px) !important;
  border-right: solid 1px #141a27 !important;
}
.title {
  font-size: 22px;
  color: #fff;
}
.el-menu-vertical-demo {
  height: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
// .el-menu-item.is-active {
//   background-color: #2c313d !important;
//   color:var(--el-color-primary);
// }
// .el-menu-item:hover {
//   background-color: #2c313d !important;
//   color: #fff;
// }
</style>
