<template>
  <!-- 顶部区域 -->
  <div class="header">
    <Hamburger></Hamburger>
    <div style="flex: 1"></div>
    <div class="header-right">
      <el-button type="primary" round plain v-if="!token" @click="Login">登录</el-button>
      <MessageIcon />
      <el-dropdown @command="clickDropdownItem" v-if="token">
        <span class="el-dropdown-link">
          <el-avatar :src="userInfo && userInfo.avatar">
            {{ userInfo?.username && userInfo.username.charAt(0) }}
          </el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="user_info">
            <a :href="userInfoUrl" target="_blank" class="user-info">个人信息</a>
          </el-dropdown-item>
          <el-dropdown-item divided command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <AddIcon />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { allUnreadMessage } from '@/api/messageApi'
import Hamburger from './Hamburger.vue'
import AddIcon from './AddIcon.vue'
import MessageIcon from './MessageIcon.vue'
export default {
  name: 'Content',
  components: { Hamburger, AddIcon, MessageIcon },
  props: ['isCollapse'],
  data() {
    return {
      userInfoUrl: process.env.VUE_APP_USERINFOURL
    }
  },
  methods: {
    ...mapMutations('user', ['setToken', 'setUserInfo', 'clearToken', 'clearUserInfo']),
    ...mapActions('user', ['getLoginOauthParams', 'userLogout']),
    ...mapMutations('message', ['setUnreadCount']),
    clickDropdownItem(command) {
      if (command === 'logout') {
        this.logout()
      }
    },
    Login() {
      this.getLoginOauthParams().then((value) => {
        window.location.href = value
      })
    },
    // 退出登录
    logout() {
      this.$confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.userLogout()
          this.$router.push('/')
        })
        .catch(() => {
          console.log('取消退出')
        })
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'token', 'unreadCount'])
  },
  mounted() {
    if (this.$route.query.code) {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="less">
.header {
  height: 100%;
  display: flex;
  align-items: center;
  .header-right {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .message {
      margin-right: 25px;
      cursor: pointer;
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: @primary;
}
.user-info:hover {
  color: @primary !important;
}
</style>
