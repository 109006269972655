<template>
  <el-dialog
    :title="title"
    :visible.sync="addDialogVisible"
    :before-close="close"
    width="850px"
    top="8vh"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      :label-width="formLabelWidth95"
      :disabled="title === '查看工单'"
    >
      <el-row>
        <el-col>
          <el-form-item label="工单标题" prop="caseTitle" class="item">
            <el-input
              v-model="form.caseTitle"
              autocomplete="off"
              :disabled="form.status !== 1100 && form.status >= 12"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="所属公司" prop="orgId">
            <el-select
              v-model="form.orgId"
              placeholder="请选择"
              class="w100"
              :disabled="form.status !== 1100 && form.status >= 12"
            >
              <el-option
                v-for="item in orgList"
                :key="item.id"
                :label="item.orgName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="所属产品" prop="productId">
            <el-select
              v-model="form.productId"
              placeholder="请选择"
              class="w100"
              :disabled="form.status !== 1100 && form.status >= 12"
            >
              <el-option
                v-for="item in productList"
                :key="item.id"
                :label="item.productName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="产品版本" prop="versionId">
            <el-select
              v-model="form.versionId"
              placeholder="请选择"
              class="w100"
              :disabled="form.status !== 1100 && form.status >= 12"
            >
              <el-option
                v-for="item in versionList"
                :key="item.id"
                :label="item.version"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="工单类型" prop="severity">
            <el-select
              v-model="form.typeId"
              placeholder="请选择"
              class="w100"
              :disabled="form.status !== 1100 && form.status >= 12"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="优先级" prop="severity">
            <el-select v-model="form.severity" placeholder="请选择" class="w100">
              <el-option
                v-for="item in severityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="关联工单" prop="relatedCaseIds">
            <el-select
              v-model="form.relatedCaseIds"
              multiple
              collapse-tags
              placeholder="请选择"
              class="w100"
              popper-class="related-case"
            >
              <el-option
                v-for="item in relatedCaseOptions"
                :key="item.id"
                :label="item.caseTitle"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="联系邮箱" prop="emails">
            <el-tag
              :key="tag"
              v-for="tag in form.emails"
              :closable="title !== '查看工单'"
              :disable-transitions="false"
              @close="handleClose(tag)"
              size="large"
              class="mr10"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="w200"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
            <el-button v-else class="button-new-tag" @click="showInput">添加</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="title === '新建工单' || form.status == 1100">
        <el-col>
          <el-form-item label="描述内容" prop="caseContent">
            <CaseContent ref="myEditor" @sendHtml="sendHtml" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="title === '新建工单' || form.status == 1100">
        <el-col>
          <el-form-item label="附件">
            <Uploader
              ref="uploadRef"
              :options="{ target: uploadUrl, mergeFn }"
              @fileSuccess="fileSuccess"
            />
          </el-form-item>
        </el-col>
        <el-col v-show="form.filePath.length!==0">
          <el-form-item label="已上传附件">
            <el-upload
              action=""
              :auto-upload="false"
              :on-remove="handleRemove"
              :file-list="form.filePath"
            ></el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" v-if="title !== '查看工单'">
      <el-button @click="close">取 消</el-button>
      <el-button type="success" @click="resetForm">重 置</el-button>
      <el-button
        type="warning"
        :loading="draftLoading"
        @click="saveDraft"
        v-if="!form.status || form.status === 1100"
      >
        保存草稿
      </el-button>
      <el-button type="primary" :loading="loading" @click="submitForm">提 交</el-button>
      <!-- <el-popover
        placement="top"
        width="180"
        style="margin-left: 10px"
        v-if="!form.status || form.status === 1100"
      >
        <el-button size="small" :loading="draftLoading" @click="saveDraft">保存草稿</el-button>
        <el-button size="small" :loading="loading" @click="submitForm">提交</el-button>
        <el-button slot="reference" type="primary">确 定</el-button>
      </el-popover> -->
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import CaseContent from '@/components/wangEditor/index.vue'
import Upload from '@/components/Upload.vue'
import Uploader from '@/components/Uploader/index.vue'
import {
  allOrg,
  allProduct,
  allVersion,
  allType,
  createCase,
  updateCase,
  checkCaseTitle,
  relateCaseApi
} from '@/api/caseManage/myCaseApi'
import { severityOptions, formLabelWidth95, isWangEditorEmpty } from '@/utils/utils'
import { deleteFile, mergeFile } from '@/api'

export default {
  components: { CaseContent, Upload, Uploader },
  props: {
    title: {
      type: String,
      require: true
    },
    rowData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    // 产品工单名校验是否存在
    const checkCase = (rule, value, callback) => {
      checkCaseTitle({ caseTitle: value }).then((res) => {
        if (res.data) {
          return callback(new Error('工单标题已存在！'))
        } else {
          callback()
        }
      })
    }
    return {
      addDialogVisible: false,
      formLabelWidth95,
      form: {
        caseTitle: '', // 工单标题
        orgId: '', // 所属公司
        productId: '', // 公司的产品
        versionId: '', // 公司产品的版本
        typeId: '', // 公司产品版本的工单类型
        severity: '', // 优先级
        caseContent: '', // 详细内容
        filePath: [], // 已上传的文件路径数组
        relatedCaseIds: [],
        emails: []
      },
      orgList: [],
      orgObj: {},
      productList: [],
      versionList: [],
      typeList: [],
      severityOptions,
      relatedCaseOptions: [],
      rules: {
        caseTitle: [
          { required: true, message: '请输入工单标题', trigger: 'blur' },
          { min: 3, max: 64, message: '标题长度在 3 到 64 个字符', trigger: 'blur' }
          // { validator: checkCase, trigger: 'blur' }
        ],
        orgId: [{ required: true, message: '请选择', trigger: 'blur' }],
        productId: [{ required: true, message: '请选择', trigger: 'blur' }],
        versionId: [{ required: true, message: '请选择', trigger: 'blur' }],
        // typeId: [{ required: true, message: '请选择', trigger: 'blur' }],
        severity: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      loading: false,
      draftLoading: false,
      uploadUrl: '/api/support/file/upload/to/server',
      status: 0, // 工单状态，>=12时只允许修改优先级
      uploadedImageList: [],
      inputVisible: false,
      inputValue: ''
    }
  },
  methods: {
    isWangEditorEmpty,
    // 1.获取所有公司
    getAllOrg() {
      allOrg({ userId: this.userInfo.userId }).then((res) => {
        if (res.data.length === 0) {
          this.$message.info('请先加入一个组织！')
        } else {
          this.orgList = res.data
          res.data.forEach((item) => (this.orgObj[item.id] = item.orgName))
        }
      })
    },
    // 2.根据orgId获取公司产品
    getAllProduct() {
      allProduct().then((res) => {
        this.productList = res.data
      })
    },
    // 3.根据productId获取公司产品版本
    getAllVersion(productId) {
      allVersion(productId).then((res) => {
        this.versionList = res.data
      })
    },
    // 4.根据productId和version获取工单类型
    getAllType() {
      allType({ id: this.form.productId }).then((res) => {
        this.typeList = res.data
      })
    },
    // 富文本编辑器的内容
    sendHtml(val, uploadedImageList) {
      this.form.caseContent = val
      this.uploadedImageList = uploadedImageList
    },
    // 提交
    submitForm() {
      this.loading = true
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const {
            caseTitle,
            orgId,
            productId,
            versionId,
            typeId,
            severity,
            caseContent,
            filePath,
            relatedCaseIds,
            emails
          } = this.form
          if (this.isWangEditorEmpty(caseContent) && filePath.length === 0) {
            this.$message.info('请输入反馈内容或上传附件！')
            this.loading = false
            return
          }
          const params = {
            caseTitle,
            orgId,
            orgName: this.orgObj[orgId],
            productId,
            versionId,
            typeId,
            severity,
            caseContent,
            filePath,
            relatedCaseIds,
            emails
          }
          !this.form.id && this.handleUploadImg()
          try {
            const res = this.form.id
              ? await updateCase({ ...params, id: this.form.id, status: 11 })
              : await createCase(params)
            this.$message.success(res.msg) //
            this.close() // 关闭表单，清空表单数据
            if (this.$parent.init) {
              this.$parent.init() // 重新获取表格数据
            }
            this.loading = false
          } catch (error) {
            this.loading = false
          }
        } else {
          this.loading = false
          console.log('error submit!!')
          return false
        }
      })
    },
    // 保存草稿
    async saveDraft() {
      this.draftLoading = true
      const {
        caseTitle,
        orgId,
        productId,
        versionId,
        typeId,
        severity,
        caseContent,
        filePath,
        relatedCaseIds,
        emails
      } = this.form
      if (caseTitle.trim() === '') {
        this.$message.info('请输入工单标题')
        this.draftLoading = false
        return
      }
      const params = {
        caseTitle,
        orgId,
        orgName: this.orgObj[orgId],
        productId,
        versionId,
        typeId,
        severity,
        caseContent,
        filePath,
        relatedCaseIds,
        emails
      }
      console.log('params', params)
      this.handleUploadImg()
      try {
        const res = this.form.id
          ? await updateCase({ ...params, id: this.form.id, status: 1100 })
          : await createCase({ ...params, status: 1100 })
        this.$message.success(res.msg)
        this.close()
        if (this.$parent.init) {
          this.$parent.init() // 重新获取表格数据
        }
        this.draftLoading = false
      } catch (error) {
        this.draftLoading = false
      }
    },
    handleUploadImg() {
      const imgList = this.$refs.myEditor.editor.getElemsByType('image')
      const alts = imgList.map((item) => item.alt)
      this.uploadedImageList.forEach((item) => {
        if (!alts.includes(item)) {
          const fileId = item.split('+')[0]
          const name = item.split('+')[1]
          deleteFile({ fileId, name })
        }
      })
    },
    // 重置
    resetForm() {
      this.$refs.form.resetFields()
      this.form = {
        id: '',
        caseTitle: '',
        orgId: '',
        productId: '',
        versionId: '',
        typeId: '',
        severity: '',
        caseContent: '',
        filePath: [],
        status: '',
        relatedCaseIds: [],
        emails: []
      }
      this.uploadedImageList = []
    },
    // 取消
    close() {
      this.addDialogVisible = false
      this.resetForm()
    },
    // 文件列表移除文件时的钩子
    handleRemove(file) {
      deleteFile({ fileId: file.id, name: file.name }).then((res) => {
        this.form.filePath = this.form.filePath.filter((item) => {
          return item.id !== file.id
        })
      })
    },
    // 文件上传成功时的钩子
    handleSuccess(res) {
      this.$message.success('文件上传成功！')
      res.data.forEach((item) => {
        this.form.filePath.push(item)
      })
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      console.log('uploadError-----', err)
    },
    getRelatedCase() {
      relateCaseApi().then((res) => {
        this.relatedCaseOptions = res.data
      })
    },
    handleClose(tag) {
      this.form.emails.splice(this.form.emails.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.focus()
      })
    },

    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.form.emails.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    async mergeFn(params) {
      console.log('合并--params', params)
      return new Promise((resolve, reject) => {
        mergeFile(params)
          .then(({ data }) => {
            console.log('合并成功')
            resolve(data)
          })
          .catch(() => {
            console.log('合并失败')
            reject('合并失败')
          })
      })
    },
    fileSuccess(file) {
      console.log('fileSuccess', file)
      this.$message.success('文件上传成功！')
      this.form.filePath.push(file)
      const uploaderInstance = this.$refs.uploadRef.$refs.uploader
      uploaderInstance.fileList[0].cancel()
    }
  },
  watch: {
    // 父组件点击编辑或查看时传的row
    rowData(val) {
      const {
        id,
        caseTitle,
        orgId,
        productId,
        versionId,
        typeId,
        severity,
        caseContent,
        status,
        filePath,
        relatedCaseIds,
        emails
      } = val
      this.form = {
        id,
        caseTitle,
        orgId,
        productId,
        versionId,
        typeId,
        severity,
        caseContent,
        status,
        filePath,
        relatedCaseIds: relatedCaseIds || [],
        emails: emails || []
      }
      setTimeout(() => {
        if (this.$refs.myEditor && this.$refs.myEditor.editor) {
          this.$refs.myEditor.editor.setHtml(this.form.caseContent)
          if (this.title === '查看工单') {
            this.$refs.myEditor.editor.disable()
          } else {
            this.$refs.myEditor.editor.enable()
          }
        }
      })
    },
    // 监听所属公司产品的改变
    'form.productId': {
      handler(productId, oldValue) {
        if (oldValue !== '') {
          this.form.versionId = ''
          this.form.typeId = ''
        }
        console.log('productId', productId)
        this.versionList = []
        this.typeList = []
        if (productId) {
          this.getAllVersion(productId)
          this.getAllType()
        }
      }
    },
    addDialogVisible(val) {
      if (val) {
        this.getAllOrg()
        this.getAllProduct()
        this.getRelatedCase()
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  }
}
</script>

<style lang="less" scoped>
.item :deep(.el-form-item__label) {
  color: @primary;
}
.el-dialog__wrapper {
  // z-index: 99999 !important;
}
:deep(.el-upload--text) {
  display: none;
}
</style>
<style>
.related-case {
  width: 300px !important;
}
</style>
