import store from '@/store'

export default {
  inserted(el, binding, vnode) {
    const { value } = binding
    const auths = (store.getters.userInfo && store.getters.userInfo.authorities) || []
    if (value && value instanceof Array && value.length > 0) {
      const permissionAuths = value

      const hasPermission = auths.some((auth) => {
        return permissionAuths.includes(auth)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error("need roles! Like v-permission=\"['admin','editor']\"")
    }
  }
}
