import request from '@/utils/request'

/**
 * @description: 获取所有消息
 */
export const allMessage = (params) => request.get('/api/support/notification/list/all', { params })
/**
 * @description: 标记站内信状态为已读
 */
export const markAsRead = (data) =>
  request({
    method: 'post',
    url: '/api/support/notification/read/notification',
    data
  })
/**
 * @description: 获取未读消息数
 */
export const allUnreadMessage = () => request.get('/api/support/notification/count/unread')

export const newMessage = () =>
  request({
    url: '/api/support/notification/list/preview',
    method: 'get'
  })
