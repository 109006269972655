import request from '@/utils/request'

/**
 * @description: 创建工单
 */
export const createCase = (params) => request.post('/api/support/case/create', params)
/**
 * @description: 创建工单时获取自己加入的所有公司
 */
export const allOrg = (params) => request.get('/api/support/user/top/org/user', { params })

/**
 * @description: 创建工单时获取公司产品
 */
export const allProduct = () => request.get('/api/support/product/org/list/product/all')
/**
 * @description: 创建工单时根据productId获取公司产品所属版本
 */
export const allVersion = (productId) =>
  request.get(`/api/support/product/${productId}/list/version`)
/**
 * @description: 创建工单时获取工单类型
 */
export const allType = (params) => request.get('/api/support/product/list/type/all', { params })

/**
 * @description: 分页获取我的工单(待处理/已处理)
 */
export const myCase = (params) => request.get('/v1/case/list/my/case', { params })
/**
 * @description: 根据工单id查询工单详情
 */
export const findCase = (caseId) => request.get(`/v1/case/${caseId}/find`)
/**
 * @description: 根据状态分页获取客户创建的工单
 */
export const customerCase = (params) => request.post('/api/support/case/list/customer/case', params)
export const draftCase = (params) => request.get('/api/support/case/queryDraft', { params })
/**
 * @description: 更新工单
 */
export const updateCase = (params) => request.post('/api/support/case/update', params)
/**
 * @description: 创建工单流转记录
 */
export const createCaseFlow = (params) => request.post('/api/support/case/flow/create', params)
/**
 * @description: 工单流转记录展示
 */
export const caseFlowList = (caseId, data) =>
  request.post(`/api/support/case/flow/list/${caseId}`, data)
/**
 * @description: 工单提交为已完成待确认
 */
export const submitConfirm = (caseId) =>
  request.post(`/api/support/case/flow/submit/confirm/${caseId}`)
/**
 * @description: 客户确认工单完成
 */
export const confirmComplete = (params) =>
  request.post('/api/support/case/flow/confirm/complete', params)
/**
 * @description: 关闭工单
 */
export const close = (caseId) => request.post(`/api/support/case/flow/close/${caseId}`)
/**
 * @description: 获取工单操作日志列表
 */
export const operationList = (params) => request.get('/api/support/case/list/operation', { params })
/**
 * @description: 获取所有次要负责人列表
 */
export const allAssignee = (params) => request.get('/api/support/case/list/members', { params })
/**
 * @description: 获取工单次要负责人列表
 */
export const secondAssignee = (caseId) =>
  request.get(`/api/support/case/${caseId}/secondary/assignee/list`)
/**
 * @description: 添加工单次要负责人
 */
export const addSecondAssignee = (caseId, data) =>
  request({
    method: 'post',
    url: `/api/support/case/${caseId}/secondary/assignee/add`,
    data
  })

/**
 * @description: 校验工单是否已存在
 */
export const checkCaseTitle = (params) => request.get('/api/support/case/check/case', { params })
/**
 * @description: 获取工单实体对象
 */
export const caseDetail = (caseId) => request.get(`/api/support/case/${caseId}/find`)
/**
 * @description: 获取工单相关人员（主要次要负责人信息）
 */
export const caseAssignee = (params) => request.get('/api/support/case/list/concerned', { params })

export const queryConditionsApi = () => request.get('/api/support/case/queryConditions')
export const queryConditions2Api = (params) =>
  request.get('/api/support/case/queryConditionsByProductId', { params })

export const delAssignee = (data) =>
  request({
    method: 'post',
    url: '/api/support/case/secondary/assignee/delete',
    data
  })
export const getCommentUserApi = (params) =>
  request({
    method: 'get',
    url: '/api/support/case/flow/queryUserIdAndName',
    params
  })
export const addComment = (data) =>
  request({
    method: 'post',
    url: '/api/support/evaluate/evaluate/add',
    data
  })

export const relateCaseApi = () => request.get('/api/support/case/queryCaseToBeRelated')
export const relatedCaseApi = (params) => request.get('/api/support/case/queryRelated', { params })
