import request from '@/utils/request'

/**
 * @description: 获取当前 Token 的用户信息
 */
export const getTokenUser = () => request.get('/api/support/user/get/current/user')
/**
 * @description: 退出登录
 */
export const userLogout = (token) => request.post(`/api/support/user/logout?token=${token}`)

/**
 * @description: 请求授权码所需参数
 */
export const getOauthParams = () => request.get('/api/support/oauth/codeParams')

/**
 * @description:请求token
 */
export const getOauthToken = (params) =>
  request.get('/api/support/oauth/oauth2CodeModel', { params })
