import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './plugins'
import '@/assets/css/normalize.css'
import '@/assets/css/global.css'
import '@/assets/fonts/iconfont.css'
import uploader from 'vue-simple-uploader'
Vue.config.productionTip = false
// element全局配置
Vue.prototype.$ELEMENT = { size: 'small' }
Vue.use(uploader)
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this // 安装全局事件总线
  }
}).$mount('#app')
