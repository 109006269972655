import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import store from '../store'
import { removeToken } from '@/utils/token'
// 配置项
const axiosOption = {
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
}
const rLogin = (msg) => {
  removeToken()
  store.commit('user/clearToken')
  store.commit('user/clearUserInfo')
  Message.error(msg)
  router.replace('/')
}
// 创建一个单例
const instance = axios.create(axiosOption)

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    const token = store.getters.token
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`
      }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    const { code, msg } = response.data
    if (code === 0) {
      return response.data
    } else {
      if (code === 10 || code === 401 || code === 3001) {
        rLogin(msg)
      } else if (code === 500) {
        Message.error('网络错误!')
      } else {
        Message.error(msg)
      }
      return Promise.reject(response)
    }
  },
  function (error) {
    const { code } = error.response.data
    if (code === 3001) {
      rLogin()
    } else {
      Message.error('网络错误!')
    }

    return Promise.reject(error)
  }
)

export default instance
