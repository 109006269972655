import { getTokenUser, getOauthParams, getOauthToken, userLogout } from '@/api/userApi'
import { constantRoutes, asyncRoutes, anyRoute } from '@/router/routes'
import checkPermission from '@/utils/permission'
import cloneDeep from 'lodash/cloneDeep'
import router from '@/router'
import { setToken, getToken, removeToken } from '@/utils/token'
const filterAsyncRoutes = (asyncRoutes, routesName) => {
  const result = asyncRoutes.filter((item) => {
    if (routesName.includes(item.name)) {
      return item
    }
  })
  result.forEach((item) => {
    if (item.children && item.children.length > 0) {
      item.children = filterAsyncRoutes(item.children, routesName)
    }
  })
  return result
}

export default {
  namespaced: true,

  state: {
    token: getToken(),
    menuRoutes: constantRoutes,
    userInfo: {}
  },

  mutations: {
    // 设置token和用户信息
    setToken(state, payload) {
      state.token = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload
    },
    // 清空token和用户信息
    clearToken(state) {
      state.token = ''
    },
    clearUserInfo(state) {
      state.userInfo = {}
    },
    setMenuRoutes(state, payload) {
      state.menuRoutes = payload
    }
  },
  actions: {
    getLoginOauthParams() {
      return new Promise((resolve, reject) => {
        getOauthParams()
          .then((res) => {
            const { scope, client_id, response_type, state, redirect_uri } = res.data
            const url = `${process.env.VUE_APP_AUTH_URL}authorize?scope=${scope}&client_id=${client_id}&response_type=${response_type}&state=${state}&redirect_uri=${redirect_uri}`
            resolve(url)
          })
          .catch(() => {
            reject()
          })
      })
    },
    getToken({ commit }, code) {
      return new Promise((resolve, reject) => {
        getOauthToken({ code })
          .then((res) => {
            const { access_token } = res.data
            setToken(access_token)
            commit('setToken', access_token)
            resolve(access_token)
          })
          .catch(() => {
            reject()
          })
      })
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getTokenUser()
          .then((res) => {
            commit('setUserInfo', res.data)
            const hasPermission = checkPermission(['SUPPORT_JINMU_COMPANY_MEMBER'])
            let routesName = []
            if (hasPermission) {
              routesName = [
                'case',
                'myCase',
                'caseAssign',
                'caseDetails',
                'caseDraft',
                'setting',
                'customer',
                'log',
                'contacts',
                'contacts-item'
              ]
            } else {
              routesName = [
                'case',
                'myCase',
                'caseDetails',
                'caseDraft',
                'contacts',
                'contacts-item'
              ]
            }
            const userAsyncRoutes = filterAsyncRoutes(cloneDeep(asyncRoutes), routesName)
            commit('setMenuRoutes', [...constantRoutes, ...userAsyncRoutes])

            const asy = [...userAsyncRoutes, anyRoute]
            asy.forEach((item) => {
              router.addRoute(item)
            })
            resolve()
          })
          .catch(() => {
            reject('获取用户信息失败')
          })
      })
    },
    userLogout({ commit, token }) {
      return userLogout(token).then((res) => {
        commit('clearToken')
        commit('clearUserInfo')
        removeToken()
      })
    }
  }
}
