<template>
  <el-container class="wrap">
    <el-aside :class="[isCollapse ? 'w64' : 'w200']">
      <MyMenu />
    </el-aside>
    <el-container>
      <el-header><Header /></el-header>
      <el-main><Main /></el-main>
      <el-footer>whaleal-support</el-footer>
    </el-container>
  </el-container>
</template>
<script>
import Header from './Header.vue'
import MyMenu from './MyMenu.vue'
import Main from './Main.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Header,
    MyMenu,
    Main
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['isCollapse'])
  }
}
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
}
.w64 {
  width: 64px !important;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.w200 {
  width: 200px !important;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.el-header {
  background-color: #fff;
  border-bottom: 1px solid #ebeef4;
}
.el-main {
  padding: 0;
}
.el-footer {
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #ebeef4;
}
</style>
