import Vue from 'vue'
import VueRouter from 'vue-router'
import { constantRoutes } from './routes'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes
})

const whiteList = ['/dashboard', '/product']

router.beforeEach(async (to, from, next) => {
  NProgress.start()

  const { userId } = store.getters.userInfo
  let token = store.getters.token
  if (!token && to.query.code) {
    try {
      token = await store.dispatch('user/getToken', to.query.code)
    } catch (error) {
      console.log('获取toke失败')
    }
  }

  if (token) {
    if (userId) {
      next()
    } else {
      try {
        await store.dispatch('user/getUserInfo')
        next({ ...to, replace: true })
      } catch (error) {
        await store.dispatch('user/userLogout', token)
        next({ path: '/' })
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      store.dispatch('user/getLoginOauthParams').then((value) => {
        window.location.href = value
      })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
export default router
