<template>
  <!-- 内容区域--路由出口 -->
  <div>
    <router-view></router-view>
    <el-backtop target=".el-main" :right="112"/>
  </div>
</template>

<script>
export default {
  name: 'Content'
}
</script>
