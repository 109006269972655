export default {
  namespaced: true,

  state: {
    isCollapse: false
  },

  actions: {},

  mutations: {
    setCollapse(state) {
      state.isCollapse = !state.isCollapse
    }
  }
}
