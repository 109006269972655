import request from '@/utils/request'

export const uploadFile = (url, data) =>
  request({
    url,
    method: 'post',
    data,
    withCredentials: true
  })
export const deleteFile = (data) =>
  request({
    url: '/api/support/file/deleteFile',
    method: 'post',
    data
  })

export const mergeFile = (data) =>
  request({
    url: '/api/support/file/merge',
    method: 'post',
    data
  })
