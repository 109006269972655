<template>
  <div>
    <div style="border: 1px solid #ccc">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <Editor
        style="height: 400px"
        v-model="html"
        :defaultConfig="editorConfig"
        @onChange="onChange"
        @onCreated="onCreated"
      />
      <mention-modal
        v-if="isShowModal && showMention"
        @hideMentionModal="hideMentionModal"
        @insertMention="insertMention"
        :showList="showList"
      ></mention-modal>
    </div>
  </div>
</template>

<script>
import { Boot, DomEditor } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import mentionModule from '@wangeditor/plugin-mention'
import MentionModal from './MentionModal'
import { getSafeHtml } from '@/utils/utils'
import { Message } from 'element-ui'
import { uploadFile, deleteFile } from '@/api'
// 注册插件
Boot.registerModule(mentionModule)

export default {
  name: 'MyEditorWithMention',
  components: { Editor, Toolbar, MentionModal },
  props: {
    showList: {
      type: Array,
      default: () => []
    },
    showMention: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const _this = this
    return {
      editor: null,
      html: '',
      toolbarConfig: {
        excludeKeys: ['group-video']
      },
      editorConfig: {
        placeholder: '请输入内容...',

        EXTEND_CONF: {
          mentionConfig: {
            showModal: this.showMentionModal,
            hideModal: this.hideMentionModal
          }
        },
        MENU_CONF: {
          uploadImage: {
            // 自定义上传
            async customUpload(file, insertFn) {
              const formData = new FormData()
              formData.append('files', file)
              uploadFile('/api/support/file/upload/to/server', formData).then((res) => {
                const url = res.data.url
                const alt = res.data.id + '+' + res.data.name
                const href = res.data.url
                insertFn(url, alt, href)
              })
            }
          },
          insertImage: {
            onInsertedImage(imageNode) {
              if (imageNode == null) return
              const { alt } = imageNode
              _this.uploadedImageList.push(alt)
            }
          }
        }
      },
      isShowModal: false,
      uploadedImageList: []
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
      console.log('this.editor', this.editor)
    },
    onChange(editor) {
      const content = getSafeHtml(editor.getHtml())
      this.$emit('sendHtml', content, this.uploadedImageList)

      // const toolbar = DomEditor.getToolbar(editor)
      // const curToolbarConfig = toolbar.getConfig()
      // console.log(curToolbarConfig.toolbarKeys)
    },
    showMentionModal() {
      this.isShowModal = true
    },
    hideMentionModal() {
      this.isShowModal = false
    },
    insertMention(id, name) {
      const mentionNode = {
        type: 'mention', // 必须是 'mention'
        value: name,
        info: { id },
        children: [{ text: '' }] // 必须有一个空 text 作为 children
      }
      const editor = this.editor
      if (editor) {
        editor.restoreSelection() // 恢复选区
        editor.deleteBackward('character') // 删除 '@'
        editor.insertNode(mentionNode) // 插入 mention
        editor.move(1) // 移动光标
      }
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
